import { FETCH_GOVERNANCE_POLICY_EVALUATION_DASHBOARD } from 'constants/ActionTypes';
const results = (state = {}, action) => {
  switch (action.type) {
    case FETCH_GOVERNANCE_POLICY_EVALUATION_DASHBOARD:
      return action.payload;
    default:
      return state;
  }
};

export default results;
