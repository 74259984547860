import { combineReducers } from 'redux';
import policies from './policies';
import policySuites from './policySuites';
import policyAdoptions from './policiesAdoptions';
import policyEvaluations from './policyEvaluations';
import policyImplementations from './policyImplementations';
import policiesDashboards from './policiesDashboards';
export default combineReducers({
  policies,
  policySuites,
  policyAdoptions,
  policyEvaluations,
  policyImplementations,
  policiesDashboards
});
