import { BlindataApi } from './ApiUtils';

export default class LoginApi extends BlindataApi {
  token = '';

  getAuthSettings(params) {
    return this.post('/auth/settings', params);
  }

  login(username, password) {
    return this.post('/auth/login', { username, password });
  }

  logout() {
    return this.get('/auth/logout');
  }

  getAuthenticatedUser({ access_token }) {
    let api = new BlindataApi();
    api.token = access_token;
    return api.get('/auth/introspection');
  }

  putPassword(request) {
    return this.put('/api/v1/account/password', request);
  }

  getAccount() {
    return this.get('/api/v1/account');
  }

  getAccountResultingPermissions() {
    return this.get('/api/v1/account/resulting-permissions');
  }

  getTeamsConfiguration() {
    return this.get('/api/v1/account/teams-configuration');
  }

  resetPasswordStart(request) {
    return this.post('/api/v1/account/reset-password/init', request);
  }

  resetPasswordFinish(request) {
    return this.post('/api/v1/account/reset-password/finish', request);
  }

  getResourceAuthorization({
    resourceType,
    resourceIdentifier
  }) {
    return this.get('/api/v1/account/resource-authorization', { resourceIdentifier, resourceType })
  }

  verifyOIDCIdToken(provider, idTokenRequest) {
    return this.post(`/auth/oidc/${provider}/id_token/verify`, idTokenRequest);
  }

  verifyOIDCCode(provider, { code }) {
    return this.post(`/auth/oidc/${provider}/code/verify`, { code });
  }
}
