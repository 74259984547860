import evaluations from './governancePoliciesEvaluations';
import coverage from './governancePoliciesCoverage';
import timeline from './governancePoliciesTimeline';
import { combineReducers } from 'redux';
import {
  CLEAR_GOVERNANCE_POLICY_FILTERS_DASHBOARD,
  SET_GOVERNANCE_POLICY_FILTERS_DASHBOARD
} from 'constants/ActionTypes';
import moment from 'moment';

const defaultFilters = {
  hasFilters: false,
  policy: null,
  resource: null,
  suite: null,
  fromDate: moment().subtract(1, 'month').toISOString(),
  toDate: null
};

const filters = (state = defaultFilters, action) => {
  switch (action.type) {
    case SET_GOVERNANCE_POLICY_FILTERS_DASHBOARD:
      return { ...state, ...action.payload, hasFilters: true };
    case CLEAR_GOVERNANCE_POLICY_FILTERS_DASHBOARD:
      return defaultFilters;
    default:
      return state;
  }
};

export default combineReducers({
  evaluations,
  coverage,
  timeline,
  filters
});
