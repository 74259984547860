/**
 * Login actions.
 */
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

/**
 * Behavioural.
 */
export const SHOW_PROGRESS = 'SHOW_PROGRESS';
export const HIDE_PROGRESS = 'HIDE_PROGRESS';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';
export const SEND_ERROR = 'SEND_ERROR';

/**
 * Logical Namespaces
 */
export const FETCH_LOGICAL_NAMESPACES_PAGE = 'FETCH_LOGICAL_NAMESPACES_PAGE';
export const SEARCH_LOGICAL_NAMESPACE = 'SEARCH_LOGICAL_NAMESPACE';
export const SET_LOGICAL_NAMESPACES_FILTERS = 'SET_LOGICAL_NAMESPACES_FILTERS';
export const CLEAR_LOGICAL_NAMESPACES_FILTERS = 'CLEAR_LOGICAL_NAMESPACES_FILTERS';
export const EXPORT_LOGICAL_NAMESPACES = 'EXPORT_LOGICAL_NAMESPACES';

/**
 * Logical Predicates
 */
export const FETCH_LOGICAL_PREDICATES_PAGE = 'FETCH_LOGICAL_PREDICATES_PAGE';
export const FETCH_LOGICAL_PREDICATE = 'FETCH_LOGICAL_PREDICATE';
export const SEARCH_LOGICAL_PREDICATE = 'SEARCH_LOGICAL_PREDICATE';
export const CREATE_LOGICAL_PREDICATE = 'CREATE_LOGICAL_PREDICATE';
export const MODIFY_LOGICAL_PREDICATE = 'MODIFY_LOGICAL_PREDICATE';
export const DELETE_LOGICAL_PREDICATE = 'DELETE_LOGICAL_PREDICATE';
export const SET_LOGICAL_PREDICATES_FILTERS = 'SET_LOGICAL_PREDICATES_FILTERS';
export const CLEAR_LOGICAL_PREDICATES_FILTERS = 'CLEAR_LOGICAL_PREDICATES_FILTERS';
export const EXPORT_LOGICAL_PREDICATES = '  EXPORT_LOGICAL_PREDICATES';
/**
 * DataCategories actions
 */
export const FETCH_DATACATEGORIES_PAGE = 'FETCH_DATA_CATEGORYS_PAGE';
export const CLEAR_DATACATEGORIES = 'CLEAR_DATA_CATEGORYS';
export const CREATE_DATACATEGORY = 'CREATE_DATA_CATEGORY';
export const MODIFY_DATACATEGORY = 'MODIFY_DATA_CATEGORY';
export const DELETE_DATACATEGORY = 'DELETE_DATA_CATEGORY';
export const SEARCH_DATACATEGORIES = 'SEARCH_DATA_CATEGORYS';
export const FETCH_DATACATEGORY = 'FETCH_DATA_CATEGORY';
export const SET_DATACATEGORIES_FILTER = 'SET_DATA_CATEGORYS_FILTERS';
export const CLEAR_DATACATEGORIES_FILTER = 'CLEAR_DATA_CATEGORYS_FILTERS';
export const EXPORT_DATACATEGORIES = 'EXPORT_DATACATEGORIES';
export const FETCH_DATACATEGORY_ATTRIBUTES_PAGE = 'FETCH_DATACATEGORY_ATTRIBUTES_PAGE';

/**
 * LogicalFields actions
 */
export const REQUEST_LOGICAL_FIELDS = 'REQUEST_LOGICAL_FIELDS';
export const FETCH_LOGICAL_FIELDS_PAGE = 'FETCH_LOGICAL_FIELDS_PAGE';
export const CREATE_LOGICAL_FIELD = 'CREATE_LOGICAL_FIELD';
export const SEARCH_LOGICAL_FIELDS = 'SEARCH_LOGICAL_FIELDS';
export const FETCH_LOGICAL_FIELD = 'FETCH_LOGICAL_FIELD';
export const DELETE_LOGICAL_FIELD = 'DELETE_LOGICAL_FIELD';
export const MODIFY_LOGICAL_FIELD = 'MODIFY_LOGICAL_FIELD';
export const SET_LOGICAL_FIELDS_FILTERS = 'SET_LOGICAL_FIELDS_FILTERS';
export const CLEAR_LOGICAL_FIELDS_FILTERS = 'CLEAR_LOGICAL_FIELDS_FILTERS';
export const EXPORT_LOGICAL_FIELDS = 'EXPORT_LOGICAL_FIELDS';

/**
 * LogicalRelations actions
 */
export const FETCH_LOGICAL_RELATIONS_PAGE = 'FETCH_LOGICAL_RELATIONS_PAGE';
export const FETCH_LOGICAL_RELATION = 'FETCH_LOGICAL_RELATION';
export const CREATE_LOGICAL_RELATION = 'CREATE_LOGICAL_RELATION';
export const MODIFY_LOGICAL_RELATION = 'MODIFY_LOGICAL_RELATION';
export const DELETE_LOGICAL_RELATION = 'DELETE_LOGICAL_RELATION';
export const EXPORT_LOGICAL_RELATIONS = 'EXPORT_LOGICAL_RELATION';
export const FETCH_LOGICAL_RELATIONS_BY_PREDICATE = 'FETCH_LOGICAL_RELATIONS_BY_PREDICATE';

/*
 * Semantic linking actions
 */
export const FETCH_SEMANTIC_PATHS = 'FETCH_SEMANTIC_PATHS';

/*
 * Systems actions
 */
export const SEARCH_SYSTEMS = 'SEARCH_SYSTEMS';
export const FETCH_SYSTEMS_PAGE = 'FETCH_SYSTEMS_PAGE';
export const FETCH_SYSTEM = 'FETCH_SYSTEM';
export const CREATE_SYSTEM = 'CREATE_SYSTEM';
export const DELETE_SYSTEM = 'DELETE_SYSTEM';
export const MODIFY_SYSTEM = 'MODIFY_SYSTEM';
export const CLEAR_SYSTEMS_FILTERS = 'CLEAR_SYSTEMS_FILTERS';
export const SET_SYSTEMS_FILTERS = 'SET_SYSTEMS_FILTERS';
export const EXPORT_SYSTEMS = 'EXPORT_SYSTEMS';

/*
 * Physical entity actions
 */
export const RECEIVE_SEARCH_PHYSICAL_ENTITIES = 'RECEIVE_SEARCH_PHYSICAL_ENTITIES';
export const SEARCH_PHYSICAL_ENTITIES = 'SEARCH_PHYSICAL_ENTITIES';
export const FETCH_PHYSICAL_ENTITIES_PAGE = 'FETCH_PHYSICAL_ENTITIES_PAGE';
export const FETCH_PHYSICAL_ENTITY = 'FETCH_PHYSICAL_ENTITY';
export const CREATE_PHYSICAL_ENTITY = 'CREATE_PHYSICAL_ENTITY';
export const MODIFY_PHYSICAL_ENTITY = 'MODIFY_PHYSICAL_ENTITY';
export const DELETE_PHYSICAL_ENTITY = 'DELETE_PHYSICAL_ENTITY';
export const SET_PHYSICAL_ENTITY_FILTER = 'SET_PHYSICAL_ENTITY_FILTER';
export const CLEAR_PHYSICAL_ENTITY_FILTER = 'CLEAR_PHYSICAL_ENTITY_FILTER';
export const FETCH_PHYSICAL_ENTITY_SCHEMAS = 'FETCH_PHYSICAL_ENTITY_SCHEMAS';
export const FETCH_PHYSICAL_ENTITY_DATA_SET = 'FETCH_PHYSICAL_ENTITY_DATA_SET';
export const EXPORT_PHYSICAL_ENTITIES = 'EXPORT_PHYSICAL_ENTITIES';

/*
 * Physical fields actions
 */
export const SEARCH_PHYSICAL_FIELDS = 'SEARCH_PHYSICAL_FIELDS';
export const FETCH_PHYSICAL_FIELDS_PAGE = 'FETCH_PHYSICAL_FIELDS_PAGE';
export const FETCH_PHYSICAL_FIELD = 'FETCH_PHYSICAL_FIELD';
export const CREATE_PHYSICAL_FIELD = 'CREATE_PHYSICAL_FIELD';
export const DELETE_PHYSICAL_FIELD = 'DELETE_PHYSICAL_FIELD';
export const MODIFY_PHYSICAL_FIELD = 'MODIFY_PHYSICAL_FIELD';
export const EXPORT_PHYSICAL_FIELDS = 'EXPORT_PHYSICAL_FIELDS';

/*
 * Physical Constraints actions
 */
export const FETCH_PHYSICAL_CONSTRAINTS_PAGE = 'FETCH_PHYSICAL_CONSTRAINTS_PAGE';
export const FETCH_PHYSICAL_CONSTRAINTS_PAGE_BY_TYPE = 'FETCH_PHYSICAL_CONSTRAINTS_PAGE_BY_TYPE';
export const FETCH_PHYSICAL_CONSTRAINT = 'FETCH_PHYSICAL_CONSTRAINT';
export const CREATE_PHYSICAL_CONSTRAINT = 'CREATE_PHYSICAL_CONSTRAINT';
export const DELETE_PHYSICAL_CONSTRAINT = 'DELETE_PHYSICAL_CONSTRAINT';
export const MODIFY_PHYSICAL_CONSTRAINT = 'MODIFY_PHYSICAL_CONSTRAINT';
export const SET_PHYSICAL_CONSTRAINTS_FILTERS = 'SET_PHYSICAL_CONSTRAINTS_FILTERS';
export const CLEAR_PHYSICAL_CONSTRAINTS_FILTERS = 'CLEAR_PHYSICAL_CONSTRAINTS_FILTERS';
export const EXPORT_PHYSICAL_CONSTRAINTS = 'EXPORT_PHYSICAL_CONSTRAINTS';
export const FETCH_PHYSICAL_CONSTRAINT_SCHEMAS = 'FETCH_PHYSICAL_CONSTRAINT_SCHEMAS';

/*
 * ER Diagram actions
 */
export const FETCH_ER_DIAGRAM_DATA = 'FETCH_ER_DIAGRAM_DATA';

/*
 * System Routines actions
 */
export const FETCH_SYSTEM_ROUTINES_PAGE = 'FETCH_SYSTEM_ROUTINES_PAGE';
export const FETCH_SYSTEM_ROUTINE = 'FETCH_SYSTEM_ROUTINE';
export const CREATE_SYSTEM_ROUTINE = 'CREATE_SYSTEM_ROUTINE';
export const DELETE_SYSTEM_ROUTINE = 'DELETE_SYSTEM_ROUTINE';
export const MODIFY_SYSTEM_ROUTINE = 'MODIFY_SYSTEM_ROUTINE';
export const SET_SYSTEM_ROUTINES_FILTERS = 'SET_SYSTEM_ROUTINES_FILTERS';
export const CLEAR_SYSTEM_ROUTINES_FILTERS = 'CLEAR_SYSTEM_ROUTINES_FILTERS';
export const EXPORT_SYSTEM_ROUTINES = 'EXPORT_SYSTEM_ROUTINES';
export const FETCH_SYSTEM_ROUTINE_SCHEMAS = 'FETCH_SYSTEM_ROUTINE_SCHEMAS';
export const FETCH_SYSTEM_ROUTINE_CATALOG = 'FETCH_SYSTEM_ROUTINE_CATALOG';
export const SEARCH_SYSTEM_ROUTINES = 'SEARCH_SYSTEM_ROUTINES';

/*
 * Web Actions actions
 */
export const FETCH_WEB_ACTIONS_PAGE = 'FETCH_WEB_ACTIONS_PAGE';
export const FETCH_WEB_ACTION = 'FETCH_WEB_ACTION';
export const CREATE_WEB_ACTION = 'CREATE_WEB_ACTION';
export const DELETE_WEB_ACTION = 'DELETE_WEB_ACTION';
export const MODIFY_WEB_ACTION = 'MODIFY_WEB_ACTION';
export const SET_WEB_ACTIONS_FILTERS = 'SET_WEB_ACTIONS_FILTERS';
export const CLEAR_WEB_ACTIONS_FILTERS = 'CLEAR_WEB_ACTIONS_FILTERS';
export const EXPORT_WEB_ACTIONS = 'EXPORT_WEB_ACTIONS';
export const FETCH_AVAILABLE_WEB_ACTIONS = 'FETCH_AVAILABLE_WEB_ACTIONS';

/*
 * Web Hooks actions
 */
export const FETCH_WEB_HOOKS_PAGE = 'FETCH_WEB_HOOKS_PAGE';
export const FETCH_WEB_HOOK = 'FETCH_WEB_HOOK';
export const CREATE_WEB_HOOK = 'CREATE_WEB_HOOK';
export const DELETE_WEB_HOOK = 'DELETE_WEB_HOOK';
export const MODIFY_WEB_HOOK = 'MODIFY_WEB_HOOK';

/*
 * Agent Web Actions Configurations actions
 */
export const FETCH_AGENT_WEB_ACTION_CONFIGURATIONS_PAGE =
  'FETCH_AGENT_WEB_ACTION_CONFIGURATIONS_PAGE';
export const FETCH_AGENT_WEB_ACTION_CONFIGURATION = 'FETCH_AGENT_WEB_ACTION_CONFIGURATION';
export const CREATE_AGENT_WEB_ACTION_CONFIGURATION = 'CREATE_AGENT_WEB_ACTION_CONFIGURATION';
export const DELETE_AGENT_WEB_ACTION_CONFIGURATION = 'DELETE_AGENT_WEB_ACTION_CONFIGURATION';
export const MODIFY_AGENT_WEB_ACTION_CONFIGURATION = 'MODIFY_AGENT_WEB_ACTION_CONFIGURATION';
export const EXECUTE_AGENT_WEB_ACTION_CONFIGURATION = 'EXECUTE_AGENT_WEB_ACTION_CONFIGURATION';

/*
 * SystemLogin actions
 */
export const RECEIVE_SEARCH_SYSTEM_LOGINS = 'RECEIVE_SEARCH_SYSTEM_LOGINS';
export const FETCH_SYSTEM_LOGINS_PAGE = 'FETCH_SYSTEM_LOGIN_PAGE';
export const CREATE_SYSTEM_LOGIN = 'CREATE_SYSTEM_LOGIN';
export const DELETE_SYSTEM_LOGIN = 'DELETE_SYSTEM_LOGIN';
export const MODIFY_SYSTEM_LOGIN = 'MODIFY_SYSTEM_LOGIN';
export const FETCH_GRANTS_PAGE = 'FETCH_GRANTS_PAGE';
export const CREATE_GRANT = 'CREATE_GRANT';
export const DELETE_GRANT = 'DELETE_GRANT';

/*
 * DataProducts actions
 */
export const FETCH_DATA_PRODUCTS_PAGE = 'FETCH_DATA_PRODUCTS_PAGE';
export const SEARCH_DATA_PRODUCTS = 'SEARCH_DATA_PRODUCTS';
export const FETCH_DATA_PRODUCT = 'FETCH_DATA_PRODUCT';
export const CREATE_DATA_PRODUCT = 'CREATE_DATA_PRODUCT';
export const DELETE_DATA_PRODUCT = 'DELETE_DATA_PRODUCT';
export const MODIFY_DATA_PRODUCT = 'MODIFY_DATA_PRODUCT';
export const FETCH_DATA_PRODUCT_MESH = 'FETCH_DATA_PRODUCT_MESH';
export const SET_DATA_PRODUCTS_FILTERS = 'SET_DATA_PRODUCTS_FILTERS';
export const CLEAR_DATA_PRODUCTS_FILTERS = 'CLEAR_DATA_PRODUCTS_FILTERS';
export const EXPORT_DATA_PRODUCTS = 'EXPORT_DATA_PRODUCTS';
export const FETCH_DATA_PRODUCT_SERVICES_TYPES = 'FETCH_DATA_PRODUCT_SERVICES_TYPES';
export const FETCH_DATA_PRODUCTS_BY_NAME = 'FETCH_DATA_PRODUCTS_BY_NAME';
export const FETCH_DATA_PRODUCT_BY_PORT_IDENTIFIER = 'FETCH_DATA_PRODUCT_BY_PORT_IDENTIFIER';
export const FETCH_DATA_PRODUCTS_BY_PHYSICAL_ENTITY = 'FETCH_DATA_PRODUCTS_BY_PHYSICAL_ENTITY';
export const FETCH_DATA_PRODUCT_MAP = 'FETCH_DATA_PRODUCT_MAP';
export const SEARCH_DATA_PRODUCT_DOMAINS = 'SEARCH_DATA_PRODUCT_DOMAINS';
export const FETCH_DATA_PRODUCT_DOMAINS = 'FETCH_DATA_PRODUCT_DOMAINS';
export const FETCH_DATA_PRODUCT_TYPES = 'FETCH_DATA_PRODUCT_TYPES';
export const SET_DATA_PRODUCT_DOMAINS_FILTERS = 'SET_DATA_PRODUCT_DOMAINS_FILTERS';
export const CLEAR_DATA_PRODUCT_DOMAINS_FILTERS = 'CLEAR_DATA_PRODUCT_DOMAINS_FILTERS';
export const FETCH_DATA_PRODUCT_AVAILABLE_DOMAINS = 'FETCH_DATA_PRODUCT_AVAILABLE_DOMAINS';

export const FETCH_DATA_PRODUCT_RELATED_DATA_CATEGORIES =
  'FETCH_DATA_PRODUCT_RELATED_DATA_CATEGORIES';
export const FETCH_DATA_PRODUCT_RELATED_LOGICAL_FIELDS =
  'FETCH_DATA_PRODUCT_RELATED_LOGICAL_FIELDS';

export const FETCH_DATA_PRODUCT_PORT = 'FETCH_DATA_PRODUCT_PORT';
export const FETCH_DATA_PRODUCT_PORT_DEPENDENCIES = 'FETCH_DATA_PRODUCT_PORT_DEPENDENCIES';

export const FETCH_DATA_PRODUCT_DOMAINS_PAGE = 'FETCH_DATA_PRODUCT_DOMAINS_PAGE';
export const FETCH_DATA_PRODUCT_DOMAIN = 'FETCH_DATA_PRODUCT_DOMAIN';
export const CREATE_DATA_PRODUCT_DOMAIN = 'CREATE_DATA_PRODUCT_DOMAIN';
export const DELETE_DATA_PRODUCT_DOMAIN = 'DELETE_DATA_PRODUCT_DOMAIN';
export const MODIFY_DATA_PRODUCT_DOMAIN = 'MODIFY_DATA_PRODUCT_DOMAIN';

/*
 * Governance Policies Actions
 */
export const FETCH_GOVERNANCE_POLICYS_PAGE = 'FETCH_GOVERNANCE_POLICYS_PAGE';
export const FETCH_GOVERNANCE_POLICY = 'FETCH_GOVERNANCE_POLICY';
export const CREATE_GOVERNANCE_POLICY = 'CREATE_GOVERNANCE_POLICY';
export const DELETE_GOVERNANCE_POLICY = 'DELETE_GOVERNANCE_POLICY';
export const MODIFY_GOVERNANCE_POLICY = 'MODIFY_GOVERNANCE_POLICY';
export const SET_GOVERNANCE_POLICYS_FILTERS = 'SET_GOVERNANCE_POLICYS_FILTERS';
export const CLEAR_GOVERNANCE_POLICYS_FILTERS = 'CLEAR_GOVERNANCE_POLICYS_FILTERS';
/*
 * Governance Policy Suites Actions
 */
export const FETCH_GOVERNANCE_POLICY_SUITES_PAGE = 'FETCH_GOVERNANCE_POLICY_SUITES_PAGE';
export const FETCH_GOVERNANCE_POLICY_SUITE = 'FETCH_GOVERNANCE_POLICY_SUITE';
export const CREATE_GOVERNANCE_POLICY_SUITE = 'CREATE_GOVERNANCE_POLICY_SUITE';
export const DELETE_GOVERNANCE_POLICY_SUITE = 'DELETE_GOVERNANCE_POLICY_SUITE';
export const MODIFY_GOVERNANCE_POLICY_SUITE = 'MODIFY_GOVERNANCE_POLICY_SUITE';
export const SET_GOVERNANCE_POLICY_SUITES_FILTERS = 'SET_GOVERNANCE_POLICY_SUITES_FILTERS';
export const CLEAR_GOVERNANCE_POLICY_SUITES_FILTERS = 'CLEAR_GOVERNANCE_POLICY_SUITES_FILTERS';
/*
 * Governance Policy Adoptions Actions
 */
export const FETCH_GOVERNANCE_POLICY_ADOPTIONS_PAGE = 'FETCH_GOVERNANCE_POLICY_ADOPTIONS_PAGE';
export const FETCH_GOVERNANCE_POLICY_ADOPTION = 'FETCH_GOVERNANCE_POLICY_ADOPTION';
export const CREATE_GOVERNANCE_POLICY_ADOPTION = 'CREATE_GOVERNANCE_POLICY_ADOPTION';
export const DELETE_GOVERNANCE_POLICY_ADOPTION = 'DELETE_GOVERNANCE_POLICY_ADOPTION';
export const MODIFY_GOVERNANCE_POLICY_ADOPTION = 'MODIFY_GOVERNANCE_POLICY_ADOPTION';
export const FETCH_ADOPTED_POLICIES_COUNT_FOR_RESOURCE = 'GET_ADOPTED_POLICIES_FOR_RESOURCE';
export const FETCH_POLICIES_AND_ADOPTIONS_LIST = 'FETCH_POLICIES_AND_ADOPTIONS_LIST';
/*
 * Governance Policies Dashboards Actions
 */
export const FETCH_GOVERNANCE_POLICY_EVALUATION_DASHBOARD =
  'FETCH_GOVERNANCE_POLICY_EVALUATION_DASHBOARD';
export const FETCH_GOVERNANCE_POLICY_COVERAGE_DASHBOARD =
  'FETCH_GOVERNANCE_POLICY_COVERAGE_DASHBOARD';
export const FETCH_GOVERNANCE_POLICY_TIMELINE_DASHBOARD =
  'FETCH_GOVERNANCE_POLICY_TIMELINE_DASHBOARD';
export const EXPORT_GOVERNANCE_POLICY_DASHBOARD = 'EXPORT_GOVERNANCE_POLICY_DASHBOARD';
export const SET_GOVERNANCE_POLICY_FILTERS_DASHBOARD = 'SET_GOVERNANCE_POLICY_FILTERS_DASHBOARD';
export const CLEAR_GOVERNANCE_POLICY_FILTERS_DASHBOARD =
  'CLEAR_GOVERNANCE_POLICY_FILTERS_DASHBOARD';

/*
 * Governance Policy Implementations Actions
 */
export const FETCH_GOVERNANCE_POLICY_IMPLEMENTATIONS_PAGE =
  'FETCH_GOVERNANCE_POLICY_IMPLEMENTATIONS_PAGE';
export const FETCH_GOVERNANCE_POLICY_IMPLEMENTATION = 'FETCH_GOVERNANCE_POLICY_IMPLEMENTATION';
export const CREATE_GOVERNANCE_POLICY_IMPLEMENTATION = 'CREATE_GOVERNANCE_POLICY_IMPLEMENTATION';
export const DELETE_GOVERNANCE_POLICY_IMPLEMENTATION = 'DELETE_GOVERNANCE_POLICY_IMPLEMENTATION';
export const MODIFY_GOVERNANCE_POLICY_IMPLEMENTATION = 'MODIFY_GOVERNANCE_POLICY_IMPLEMENTATION';
export const FETCH_GOVERNANCE_POLICY_IMPLEMENTATIONS_PAGE_BY_POLICY =
  'FETCH_GOVERNANCE_POLICY_IMPLEMENTATIONS_PAGE_BY_POLICY';
export const FETCH_GOVERNANCE_POLICY_IMPLEMENTATION_ENGINE_NAMES =
  'FETCH_GOVERNANCE_POLICY_IMPLEMENTATION_ENGINE_NAMES';

/*
 * Governance Policy Evaluations Actions
 */
export const FETCH_GOVERNANCE_POLICY_EVALUATIONS_PAGE = 'FETCH_GOVERNANCE_POLICY_EVALUATIONS_PAGE';
export const FETCH_GOVERNANCE_POLICY_EVALUATION = 'FETCH_GOVERNANCE_POLICY_EVALUATION';
export const FETCH_GOVERNANCE_POLICY_EVALUATIONS_PAGE_BY_POLICY =
  'FETCH_GOVERNANCE_POLICY_EVALUATIONS_PAGE_BY_POLICY';
export const CREATE_GOVERNANCE_POLICY_EVALUATION = 'CREATE_GOVERNANCE_POLICY_EVALUATION';
export const FETCH_GOVERNANCE_POLICY_EVALUATIONS_PAGE_BY_RESOURCE =
  'FETCH_GOVERNANCE_POLICY_EVALUATIONS_PAGE_BY_RESOURCE';

/*
 * Blueprints Actions
 */
export const FETCH_BLUEPRINTS_PAGE = 'FETCH_BLUEPRINTS_PAGE';
export const FETCH_BLUEPRINT = 'FETCH_BLUEPRINT';
export const CREATE_BLUEPRINT = 'CREATE_BLUEPRINT';
export const DELETE_BLUEPRINT = 'DELETE_BLUEPRINT';
export const MODIFY_BLUEPRINT = 'MODIFY_BLUEPRINT';
export const SET_BLUEPRINTS_FILTERS = 'SET_BLUEPRINTS_FILTERS';
export const CLEAR_BLUEPRINTS_FILTERS = 'CLEAR_BLUEPRINTS_FILTERS';
export const INSTANTIATE_BLUEPRINT = 'INSTANTIATE_BLUEPRINT';

/*
 * DataFlows Actions
 */
export const CREATE_DATA_FLOW = 'CREATE_DATA_FLOW';
export const DELETE_DATA_FLOW = 'DELETE_DATA_FLOW';
export const MODIFY_DATA_FLOW = 'MODIFY_DATA_FLOW';
export const FETCH_DATA_FLOW = 'FETCH_DATA_FLOW';
export const FETCH_DATA_FLOWS_PAGE = 'FETCH_DATA_FLOWS_PAGE';
export const CLEAR_DATA_FLOWS_PAGE = 'CLEAR_DATA_FLOWS_PAGE';
export const EDIT_DATA_FLOW_EDITOR = 'EDIT_DATA_FLOW_EDITOR';
export const CLEAR_DATA_FLOW_EDITOR = 'CLEAR_DATA_FLOW_EDITOR';
export const EDIT_DATA_FLOW_FILTERS = 'EDIT_DATA_FLOW_FILTERS';
export const CLEAR_DATA_FLOW_FILTERS = 'CLEAR_DATA_FLOW_FILTERS';
export const FETCH_DATA_FLOW_SCOPES = 'FETCH_DATA_FLOW_SCOPES';
export const EXPORT_DATA_FLOW = 'EXPORT_DATA_FLOW';
export const EXECUTE_IMPACT_ANALYSIS = 'EXECUTE_IMPACT_ANALYSIS';
export const CLEAR_IMPACT_ANALYSIS = 'CLEAR_IMPACT_ANALYSIS';
export const SET_IMPACT_ANALYSIS_FILTERS = 'SET_IMPACT_ANALYSIS_FILTERS';
export const CLEAR_IMPACT_ANALYSIS_FILTERS = 'CLEAR_IMPACT_ANALYSIS_FILTERS';
export const EXPORT_IMPACT_ANALYSIS = 'EXPORT_IMPACT_ANALYSIS';
export const FETCH_DATA_FLOW_CHART = 'FETCH_DATA_FLOW_CHART';

/*
 * QueryBuckets Actions
 */
export const FETCH_QUERY_BUCKETS_PAGE = 'FETCH_QUERY_BUCKETS_PAGE';
export const FETCH_QUERY_BUCKET = 'FETCH_QUERY_BUCKET';
export const SEARCH_QUERY_BUCKETS = 'SEARCH_QUERY_BUCKETS';
export const CREATE_QUERY_BUCKET = 'CREATE_QUERY_BUCKET';
export const MODIFY_QUERY_BUCKET = 'MODIFY_QUERY_BUCKET';
export const DELETE_QUERY_BUCKET = 'DELETE_QUERY_BUCKET';
export const SET_QUERY_BUCKETS_FILTERS = 'SET_QUERY_BUCKETS_FILTERS';
export const CLEAR_QUERY_BUCKETS_FILTERS = 'CLEAR_QUERY_BUCKETS_FILTERS';
export const REFRESH_QUERY_BUCKET_STATEMENTS_PAGE = 'REFRESH_QUERY_BUCKET_STATEMENTS_PAGE';
export const CONFIRM_QUERY_BUCKET_STATEMENTS_PAGE = 'CONFIRM_QUERY_BUCKET_STATEMENTS_PAGE';
export const IGNORE_QUERY_BUCKET_STATEMENTS_PAGE = 'IGNORE_QUERY_BUCKET_STATEMENTS_PAGE';

/*
 * QueryStatements Actions
 */
export const FETCH_QUERY_STATEMENTS_PAGE = 'FETCH_QUERY_STATEMENTS_PAGE';
export const FETCH_QUERY_STATEMENT = 'FETCH_QUERY_STATEMENT';
export const CREATE_QUERY_STATEMENT = 'CREATE_QUERY_STATEMENT';
export const MODIFY_QUERY_STATEMENT = 'MODIFY_QUERY_STATEMENT';
export const DELETE_QUERY_STATEMENT = 'DELETE_QUERY_STATEMENT';
export const EXPORT_QUERY_STATEMENTS = 'EXPORT_QUERY_STATEMENTS';
export const SET_QUERY_STATEMENTS_FILTERS = 'SET_QUERY_STATEMENTS_FILTERS';
export const CLEAR_QUERY_STATEMENTS_FILTERS = 'CLEAR_QUERY_STATEMENTS_FILTERS';
export const FETCH_DATA_FLOW_PREVIEWS_PAGE = 'FETCH_DATA_FLOW_PREVIEWS_PAGE';
export const CREATE_DATA_FLOW_PREVIEWS = 'CREATE_DATA_FLOW_PREVIEWS';
export const DELETE_DATA_FLOW_PREVIEWS = 'DELETE_DATA_FLOW_PREVIEWS';
export const FETCH_QUERY_STATEMENT_FULL_LINEAGE = 'FETCH_QUERY_STATEMENT_FULL_LINEAGE';
export const PATCH_QUERY_STATEMENT = 'PATCH_QUERY_STATEMENT';
export const REFRESH_QUERY_STATEMENT = 'REFRESH_QUERY_STATEMENT';
export const FETCH_QUERY_STATEMENT_ENDING_NODES = 'FETCH_QUERY_STATEMENT_ENDING_NODES';
export const FETCH_DATA_FLOWS_INVOLVED_STATEMENTS = 'FETCH_DATA_FLOWS_INVOLVED_STATEMENTS';

/*
 * DataActors Actions
 */
export const FETCH_DATA_ACTOR = 'FETCH_DATA_ACTOR';
export const CREATE_DATA_ACTOR = 'CREATE_DATA_ACTOR';
export const MODIFY_DATA_ACTOR = 'MODIFY_DATA_ACTOR';
export const DELETE_DATA_ACTOR = 'DELETE_DATA_ACTOR';
export const FETCH_DATA_ACTORS = 'FETCH_DATA_ACTORS';
export const FETCH_DATA_ACTORS_PAGE = 'FETCH_DATA_ACTORS_PAGE';
export const SEARCH_DATA_ACTORS = 'SEARCH_DATA_ACTORS';
export const FETCH_DATA_ACTOR_TASK_ASSIGNMENTS = 'FETCH_DATA_ACTOR_TASK_ASSIGNMENTS';
export const FETCH_DATA_ACTOR_TASK_ASSIGNMENTS_PAGE = 'FETCH_DATA_ACTOR_TASK_ASSIGNMENTS_PAGE';
export const CREATE_DATA_ACTOR_TASK_ASSIGNMENT = 'CREATE_DATA_ACTOR_TASK_ASSIGNEMT';
export const REMOVE_DATA_ACTOR_TASK_ASSIGNMENT = 'REMOVE_DATA_ACTOR_TASK_ASSIGNMENT';
export const FETCH_DATA_ACTOR_LOGIN_ASSIGNMENTS = 'FETCH_DATA_ACTOR_LOGIN_ASSIGNMENTS';
export const CREATE_DATA_ACTOR_LOGIN_ASSIGNMENT = 'CREATE_DATA_ACTOR_LOGIN_ASSIGNEMT';
export const REMOVE_DATA_ACTOR_LOGIN_ASSIGNMENT = 'REMOVE_DATA_ACTOR_LOGIN_ASSIGNMENT';
export const SET_DATA_ACTORS_FILTERS = 'SET_DATA_ACTORS_FILTERS';
export const CLEAR_DATA_ACTORS_FILTERS = 'CLEAR_DATA_ACTORS_FILTERS';
export const EXPORT_DATA_ACTORS = 'EXPORT_DATA_ACTORS';
/*
 * Tasks Actions
 */
export const FETCH_TASKS_PAGE = 'FETCH_TASKS_PAGE';
export const SEARCH_TASKS = 'SEARCH_TASKS';
export const FETCH_TASK = 'FETCH_TASK';
export const CREATE_TASK = 'CREATE_TASK';
export const MODIFY_TASK = 'MODIFY_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const SET_TASKS_FILTERS = 'SET_TASKS_FILTERS';
export const CLEAR_TASKS_FILTERS = 'CLEAR_TASKS_FILTERS';
export const CREATE_TASK_PHYSICAL_ENTITY_ASSOCIATION = 'CREATE_TASK_PHYSICAL_ENTITY_ASSOCIATION';
export const FETCH_TASK_PHYSICAL_ENTITY_ASSOCIATIONS = 'FETCH_TASK_PHYSICAL_ENTITY_ASSOCIATIONS';
export const REMOVE_TASK_PHYSICAL_ENTITY_ASSOCIATION = 'REMOVE_TASK_PHYSICAL_ENTITY_ASSOCIATION';
export const EXPORT_TASKS = 'EXPORT_TASKS';

/*
 * Processings Actions
 */
export const FETCH_PROCESSINGS = 'FETCH_PROCESSINGS';
export const FETCH_PROCESSINGS_PAGE = 'FETCH_PROCESSINGS_PAGE';
export const SEARCH_PROCESSINGS = 'SEARCH_PROCESSINGS';
export const FETCH_PROCESSING = 'FETCH_PROCESSING';
export const CREATE_PROCESSING = 'CREATE_PROCESSING';
export const MODIFY_PROCESSING = 'MODIFY_PROCESSING';
export const DELETE_PROCESSING = 'DELETE_PROCESSING';
export const SET_PROCESSINGS_FILTERS = 'SET_PROCESSINGS_FILTERS';
export const CLEAR_PROCESSINGS_FILTERS = 'CLEAR_PROCESSINGS_FILTERS';
export const FETCH_SECURITY_MEASURES = 'FETCH_SECURITY_MEASURES';
export const EXPORT_PROCESSINGS = 'EXPORT_PROCESSINGS';
export const FETCH_PROCESSING_LEGAL_BASIS = 'FETCH_PROCESSING_LEGAL_BASIS';

/*
 * Procesing Disclaimers Actions
 */
export const FETCH_ALL_PROCESSING_DISCLAIMERS = 'FETCH_ALL_PROCESSING_DISCLAIMERS';
export const CREATE_PROCESSING_DISCLAIMER = 'CREATE_PROCESSING_DISCLAIMER';
export const FETCH_PROCESSING_DISCLAIMER = 'FETCH_PROCESSING_DISCLAIMER';
export const DELETE_PROCESSING_DISCLAIMER = 'DELETE_PROCESSING_DISCLAIMER';

/*
 * Consents Actions
 */
export const CONFIGURE_SEARCH_PARAMS = 'CONFIGURE_SEARCH_PARAMS';
export const CLEAR_CONSENTS_SEARCH = 'CLEAR_CONSENTS_SEARCH';
export const SEARCH_CONSENTS = 'SEARCH_CONSENTS';
export const FETCH_CONSENT = 'GET_CONSENT';

/**
 * Notary Actions
 */
export const FETCH_RECORDS = 'FETCH_RECORDS';
export const FETCH_PROOFS = 'FETCH_PROOFS';

/*
 * TaskGrants Actions
 */
export const FETCH_TASK_GRANTS = 'FETCH_TASKS_GRANTS';
export const CREATE_TASK_GRANT = 'CREATE_TASK_GRANT';
export const DELETE_TASK_GRANT = 'DELETE_TASK_GRANT';

/*
 * Graph Actions
 */
export const ADD_GRAPH_UUID_FILTER = 'ADD_GRAPH_UUID_FILTER';
export const REMOVE_GRAPH_UUID_FILTER = 'REMOVE_GRAPH_UUID_FILTER';
export const ADD_GRAPH_PROPERTY_FILTER = 'ADD_GRAPH_PROPERTY_FILTER';
export const REMOVE_GRAPH_PROPERTY_FILTER = 'REMOVE_GRAPH_PROPERTY_FILTER';
export const CHANGE_GRAPH_FILTER_EDITOR = 'CHANGE_GRAPH_FILTER_EDITOR';
export const CHANGE_GRAPH_PERSPECTIVE_FILTER = 'CHANGE_GRAPH_PERSPECTIVE_FILTER';
export const REQUEST_GRAPH = 'REQUEST_GRAPH';
export const RECEIVE_GRAPH = 'RECEIVE_GRAPH';
export const CLEAR_GRAPH = 'CLEAR_GRAPH';
export const CLEAR_GRAPH_FILTERS = 'CLEAR_GRAPH_FILTERS';
export const GRAPH_NODE_EXPANSION = 'GRAPH_NODE_EXPANSION';
export const FETCH_GRAPH_NODE_DETAIL = 'FETCH_GRAPH_NODE_DETAIL';
export const OPEN_GRAPH_CONTEXTUAL_MENU = 'OPEN_GRAPH_CONTEXTUAL_MENU';
export const CLOSE_GRAPH_CONTEXTUAL_MENU = 'CLOSE_GRAPH_CONTEXTUAL_MENU';
export const GRAPH_REMOVE_ELEMS = 'GRTAPH_REMOVE_ELEMS';

/**
 * Account
 */
export const FETCH_ACCOUNT_RESULTING_PERMISSIONS = 'FETCH_ACCOUNT_RESULTING_PERMISSIONS';
export const FETCH_ACCOUNT_TEAMS_CONFIGURATION = 'FETCH_ACCOUNT_TEAMS_CONFIGURATION';

/**
 *  Settings Users
 */
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_USERS_PAGE = 'FETCH_USERS_PAGE';
export const FETCH_USER = 'FETCH_USER';
export const PATCH_USER = 'PATCH_USER';
export const SEARCH_USER = 'SEARCH_USER';
export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const FETCH_USER_TENANTS = 'FETCH_USERS_TENANTS';
export const REMOVE_USER_TENANT = 'REMOVE_USER_TENANT';
export const ADD_USER_TENANT = 'ADD_USER_TENANT';
export const FETCH_DEFAULT_TENANT = 'FETCH_DEFAULT_TENANT';
export const CHANGE_DEFAULT_TENANT = 'CHANGE_DEFAULT_TENANT';
export const FETCH_USER_TENANT_PERMISSIONS = 'FETCH_USER_TENANT_PERMISSIONS';
export const MODIFY_USER_TENANT_PERMISSIONS = 'MODIFY_USER_TENANT_PERMISSIONS';
export const FETCH_TEAM_USER_SETTINGS = 'FETCH_TEAM_USER_SETTINGS';
export const MODIFY_TEAM_USER_SETTINGS = 'MODIFY_TEAM_USER_SETTINGS';
export const FETCH_UNIVERSAL_SEARCH = 'FETCH_UNIVERSAL_SEARCH';
export const FETCH_USERS_IN_CURRENT_TENANT = 'FETCH_USERS_IN_CURRENT_TENANT';
export const SET_USERS_FILTERS = 'SET_USERS_FILTERS';
export const CLEAR_USERS_FILTERS = 'CLEAR_USERS_FILTERS';

/**
 * Settings Tenants
 */
export const SELECT_TENANT = 'SELECT_TENANT';
export const FETCH_TENANTS = 'FETCH_TENANTS';

/**
 * Contracts Configurations Actions
 */
export const FETCH_CONTRACT_CONFIG_PAGE = 'FETCH_CONTRACT_CONFIG_PAGE';
export const CLEAR_CONTRACT_CONFIGS = 'CLEAR_CONTRACT_CONFIGS';
export const FETCH_CONTRACT_CONFIG = 'FETCH_CONTRACT_CONFIG';
export const CREATE_CONTRACT_CONFIG = 'CREATE_CONTRACT_CONFIG';
export const MODIFY_CONTRACT_CONFIG = 'MODIFY_CONTRACT_CONFIG';
export const DELETE_CONTRACT_CONFIG = 'DELETE_CONTRACT_CONFIG';
export const CLEAR_CONTRACT_CONFIG_EDITOR = 'EDIT_CONTRACT_CONFIG';
export const EDIT_CONTRACT_CONFIG_EDITOR = 'EDIT_CONTRACT_CONFIG_EDITOR';
export const SET_FILTERS_CONTRACT_CONFIGS = 'SET_FILTERS_CONTRACT_CONFIGS';
export const CLEAR_FILTERS_CONTRACT_CONFIGS = 'CLEAR_FILTERS_CONTRACT_CONFIGS';

/*
 * Contracts Templates Actions
 */
export const FETCH_CONTRACT_TEMPLATE_PAGE = 'FETCH_CONTRACT_TEMPLATE_PAGE';
export const CLEAR_CONTRACT_TEMPLATES = 'CLEAR_CONTRACT_TEMPLATES';
export const FETCH_CONTRACT_TEMPLATE = 'FETCH_CONTRACT_TEMPLATE';
export const CREATE_CONTRACT_TEMPLATE = 'CREATE_CONTRACT_TEMPLATE';
export const MODIFY_CONTRACT_TEMPLATE = 'MODIFY_CONTRACT_TEMPLATE';
export const DELETE_CONTRACT_TEMPLATE = 'DELETE_CONTRACT_TEMPLATE';
export const CLEAR_CONTRACT_TEMPLATE_EDITOR = 'EDIT_CONTRACT_TEMPLATE';
export const EDIT_CONTRACT_TEMPLATE_EDITOR = 'EDIT_CONTRACT_TEMPLATE_EDITOR';

export const FETCH_CONTRACT_TEMPLATE_ATTACHMENTS_PAGE = 'FETCH_CONTRACT_TEMPLATE_ATTACHMENTS_PAGE';
export const FETCH_CONTRACT_TEMPLATE_ATTACHMENT = 'FETCH_CONTRACT_TEMPLATE_ATTACHMENT';
export const CREATE_CONTRACT_TEMPLATE_ATTACHMENT = 'CREATE_CONTRACT_TEMPLATE_ATTACHMENT';
export const MODIFY_CONTRACT_TEMPLATE_ATTACHMENT = 'MODIFY_CONTRACT_TEMPLATE_ATTACHMENT';
export const DELETE_CONTRACT_TEMPLATE_ATTACHMENT = 'DELETE_CONTRACT_TEMPLATE_ATTACHMENT';

/*
 * Contracts Definitions actions
 */
export const FETCH_CONTRACT_DEFINITIONS_PAGE = 'FETCH_CONTRACT_DEFINITIONS_PAGE';
export const FETCH_CONTRACT_DEFINITION = 'FETCH_CONTRACT_DEFINITION';
export const CREATE_CONTRACT_DEFINITION = 'CREATE_CONTRACT_DEFINITION';
export const MODIFY_CONTRACT_DEFINITION = 'MODIFY_CONTRACT_DEFINITION';
export const DELETE_CONTRACT_DEFINITION = 'DELETE_CONTRACT_DEFINITION';
export const SEARCH_CONTRACT_DEFINITIONS = 'SEARCH_CONTRACT_DEFINITIONS';
export const EXPORT_CONTRACT_DEFINITIONS = 'EXPORT_CONTRACT_DEFINITIONS';
export const SET_CONTRACT_DEFINITIONS_FILTERS = 'SET_CONTRACT_DEFINITIONS_FILTERS';
export const CLEAR_CONTRACT_DEFINITIONS_FILTERS = 'CLEAR_CONTRACT_DEFINITIONS_FILTERS';
export const FETCH_CONTRACT_DEFINITION_CATEGORIES = 'FETCH_CONTRACT_DEFINITION_CATEGORIES';

/**
 * Dashboards
 */
export const FETCH_DASHBOARD_CONSENTS_LINECHART = 'FETCH_DASHBOARD_CONSENTS_LINECHART';
export const CLEAR_FILTERS_DASHBOARD_CONSENTS_LINECHART =
  'CLEAR_FILTERS_DASHBOARD_CONSENTS_LINECHART';
export const SET_FILTERS_DASHBOARD_CONSENTS_LINECHART = 'SET_FILTERS_DASHBOARD_CONSENTS_LINECHART';
export const FETCH_DASHBOARD_CONSENTS_COLUMNCHART = 'FETCH_DASHBOARD_CONSENTS_COLUMNCHART';
export const SET_FILTERS_DASHBOARD_CONSENTS_COLUMNCHART =
  'SET_FILTERS_DASHBOARD_CONSENTS_COLUMNCHART';
export const CLEAR_FILTERS_DASHBOARD_CONSENTS_COLUMNCHART =
  'CLEAR_FILTERS_DASHBOARD_CONSENTS_COLUMNCHART';

export const FETCH_DASHBOARD_DATA_SUBJECTS_AUDIENCE_COUNT =
  'FETCH_DASHBOARD_DATA_SUBJECTS_AUDIENCE_COUNT';
export const SET_FILTERS_DASHBOARD_DATA_SUBJECTS_AUDIENCE_COUNT =
  'SET_FILTERS_DASHBOARD_DATA_SUBJECTS_AUDIENCE_COUNT';
export const CLEAR_FILTERS_DASHBOARD_DATA_SUBJECTS_AUDIENCE_COUNT =
  'CLEAR_FILTERS_DASHBOARD_DATA_SUBJECTS_AUDIENCE_COUNT';

export const FETCH_DASHBOARD_SYSTEM_DATA_SUBJECT_COUNT =
  'FETCH_DASHBOARD_SYSTEM_DATA_SUBJECT_COUNT';
export const FETCH_DASHBOARD_SYSTEM_METADATA_METRICS = 'FETCH_DASHBOARD_SYSTEM_METADATA_METRICS';

/**
 * Reports
 */
export const EDIT_DATASUBJECT_REPORT_SELECTION = 'EDIT_DATASUBJECT_REPORT_SELECTION';
export const FETCH_DATASUBJECT_REPORT = 'FETCH_DATASUBJECT_REPORT';
export const CLEAR_DATASUBJECT_REPORT = 'CLEAR_DATASUBJECT_REPORT';
export const FETCH_RPA_TEMPLATES = 'FETCH_RPA_TEMPLATES';
export const FETCH_RPA_TEMPLATE = 'FETCH_RPA_TEMPLATE';
export const CREATE_RPA_TEMPLATE = 'CREATE_RPA_TEMPLATE';
export const MODIFY_RPA_TEMPLATE = 'MODIFY_RPA_TEMPLATE';
export const DELETE_RPA_TEMPLATE = 'DELETE_RPA_TEMPLATE';
export const EDIT_RPA_TEMPLATE = 'EDIT_RPA_TEMPLATE';

export const FETCH_RPA_ARCHIVES_PAGE = 'FETCH_RPA_ARCHIVES_PAGE';
export const FETCH_RPA_ARCHIVE = 'FETCH_RPA_ARCHIVE';
export const CREATE_RPA_ARCHIVE = 'CREATE_RPA_ARCHIVE';
export const MODIFY_RPA_ARCHIVE = 'MODIFY_RPA_ARCHIVE';
export const DELETE_RPA_ARCHIVE = 'DELETE_RPA_ARCHIVE';
export const FETCH_RPA_ARCHIVE_RECORDS_PAGE = 'FETCH_RPA_ARCHIVE_RECORDS_PAGE';
export const CREATE_RPA_ARCHIVE_RECORD = 'CREATE_RPA_ARCHIVE_RECORD';
export const DELETE_RPA_ARCHIVE_RECORD = 'DELETE_RPA_ARCHIVE_RECORD';

/**
 * History
 */
export const FETCH_HISTORY_RECORD = 'FETCH_HISTORY_RECORD';
export const FETCH_HISTORY_RECORD_PAGE = 'FETCH_HISTORY_RECORD_PAGE';
export const FETCH_HISTORY_RECORD_PAYLOAD = 'FETCH_HISTORY_RECORD_PAYLOAD';
export const EXPORT_HISTORY_RECORD = 'EXPORT_HISTORY_RECORD';
export const SET_HISTORYS_FILTERS = 'SET_HISTORYS_FILTERS';
export const CLEAR_HISTORYS_FILTERS = 'CLEAR_HISTORYS_FILTERS';

/**
 * Teams
 */
export const FETCH_TEAMS_LIST = 'FETCH_TEAMS_LIST';
export const FETCH_TEAM = 'FETCH_TEAM';
export const CREATE_TEAM = 'CREATE_TEAM';
export const DELETE_TEAM = 'DELETE_TEAM';
export const MODIFY_TEAM = 'MODIFY_TEAM';

/**
 * Teams Policies
 */
export const FETCH_TEAM_POLICIES_LIST_BY_USER_UUID = 'FETCH_TEAM_POLICIES_LIST_BY_USER_UUID';
export const FETCH_TEAM_POLICIES_LIST_BY_TEAM_CODE = 'FETCH_TEAM_POLICIES_LIST_BY_TEAM_CODE';
export const FETCH_TEAM_POLICY = 'FETCH_TEAM_POLICY';
export const CREATE_TEAM_POLICY = 'CREATE_TEAM_POLICY';
export const DELETE_TEAM_POLICY = 'DELETE_TEAM_POLICY';
export const MODIFY_TEAM_POLICY = 'MODIFY_TEAM_POLICY';

/**
 * Campaigns
 */
export const SEARCH_CAMPAIGNS = 'SEARCH_CAMPAIGNS';
export const FETCH_CAMPAIGNS_PAGE = 'FETCH_CAMPAIGNS_PAGE';
export const FETCH_CAMPAIGN = 'FETCH_CAMPAIGN';
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const MODIFY_CAMPAIGN = 'MODIFY_CAMPAIGN';
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
export const FETCH_CAMPAIGN_PERCENTAGE = 'FETCH_CAMPAIGN_PERCENTAGE';
export const FETCH_CAMPAIGN_TEMPORAL = 'FETCH_CAMPAIGN_TEMPORAL';
export const EXPORT_CAMPAIGNS = 'EXPORT_CAMPAIGNS';

/**
 * Issues
 */
export const FETCH_ISSUES_PAGE = 'FETCH_ISSUES_PAGE';
export const FETCH_ISSUE = 'FETCH_ISSUE';
export const CREATE_ISSUE = 'CREATE_ISSUE';
export const MODIFY_ISSUE = 'MODIFY_ISSUE';
export const DELETE_ISSUE = 'DELETE_ISSUE';
export const SET_ISSUES_FILTERS = 'SET_ISSUES_FILTERS';
export const CLEAR_ISSUES_FILTERS = 'CLEAR_ISSUES_FILTERS';
export const FETCH_ISSUE_TYPE_COUNTERS = 'FETCH_ISSUE_TYPE_COUNTERS';
export const EXPORT_ISSUES = 'EXPORT_ISSUES';

/**
 * Issues Policies
 */
export const FETCH_POLICYS_PAGE = 'FETCH_POLICYS_PAGE';
export const FETCH_POLICY = 'FETCH_POLICY';
export const CREATE_POLICY = 'CREATE_POLICY';
export const MODIFY_POLICY = 'MODIFY_POLICY';
export const DELETE_POLICY = 'DELETE_POLICY';
export const EXPORT_POLICIES = 'EXPORT_POLICIES';

/**
 * Custom roles
 */
export const FETCH_CUSTOM_ROLES_PAGE = 'FETCH_CUSTOM_ROLES_PAGE';
export const CREATE_CUSTOM_ROLE = 'CREATE_CUSTOM_ROLE';
export const DELETE_CUSTOM_ROLE = 'DELETE_CUSTOM_ROLE';

/**
 * Custom Properties
 */
export const FETCH_CUSTOM_PROPERTY_DEFINITIONS_PAGE = 'FETCH_CUSTOM_PROPERTY_DEFINITIONS_PAGE';
export const CREATE_CUSTOM_PROPERTY_DEFINITION = 'CREATE_CUSTOM_PROPERTY_DEFINITION';
export const DELETE_CUSTOM_PROPERTY_DEFINITION = 'DELETE_CUSTOM_PROPERTY_DEFINITION';
export const MODIFY_CUSTOM_PROPERTY_DEFINITION = 'MODIFY_CUSTOM_PROPERTY_DEFINITION';
export const FETCH_CUSTOM_PROPERTY_DEFINITIONS_VALUES_PAGE =
  'FETCH_CUSTOM_PROPERTY_DEFINITIONS_VALUES_PAGE';
export const EXPORT_CUSTOM_PROPERTIES = 'EXPORT_CUSTOM_PROPERTIES';

/**
 * Proposals actions
 */
export const FETCH_PROPOSALS_PAGE = 'FETCH_PROPOSALS_PAGE';
export const FETCH_PROPOSAL = 'FETCH_PROPOSAL';
export const DELETE_PROPOSAL = 'DELETE_PROPOSAL';
export const CREATE_PROPOSAL = 'CREATE_PROPOSAL';
export const MODIFY_PROPOSAL = 'MODIFY_PROPOSAL';
export const PATCH_PROPOSAL = 'PATCH_PROPOSAL';
export const SET_PROPOSALS_FILTERS = 'SET_PROPOSALS_FILTERS';
export const CLEAR_PROPOSALS_FILTERS = 'CLEAR_PROPOSALS_FILTERS';
export const SET_PROPOSAL_EDITOR = 'SET_PROPOSAL_EDITOR';
export const CLEAR_PROPOSAL_EDITOR = 'CLEAR_PROPOSAL_EDITOR';

/**
 * Templates Actions
 */
export const FETCH_TEMPLATE_PROCESSINGS_PAGE = 'FETCH_TEMPLATE_PROCESSINGS_PAGE';
export const SEARCH_TEMPLATE_PROCESSINGS = 'SEARCH_TEMPLATE_PROCESSINGS';
export const FETCH_TEMPLATE_PROCESSING = 'FETCH_TEMPLATE_PROCESSING';
export const CREATE_TEMPLATE_PROCESSING = 'CREATE_TEMPLATE_PROCESSING';
export const MODIFY_TEMPLATE_PROCESSING = 'MODIFY_TEMPLATE_PROCESSING';
export const DELETE_TEMPLATE_PROCESSING = 'DELETE_TEMPLATE_PROCESSING';

export const FETCH_TEMPLATE_TASKS_PAGE = 'FETCH_TEMPLATE_TASKS_PAGE';
export const SEARCH_TEMPLATE_TASKS = 'SEARCH_TEMPLATE_TASKS';
export const FETCH_TEMPLATE_TASK = 'FETCH_TEMPLATE_TASK';
export const CREATE_TEMPLATE_TASK = 'CREATE_TEMPLATE_TASK';
export const MODIFY_TEMPLATE_TASK = 'MODIFY_TEMPLATE_TASK';
export const DELETE_TEMPLATE_TASK = 'DELETE_TEMPLATE_TASK';

export const FETCH_TEMPLATE_MAPPINGS_PAGE = 'FETCH_TEMPLATE_MAPPINGS_PAGE';
export const FETCH_TEMPLATE_MAPPING = 'FETCH_TEMPLATE_MAPPING';
export const CREATE_TEMPLATE_MAPPING = 'CREATE_TEMPLATE_MAPPING';
export const MODIFY_TEMPLATE_MAPPING = 'MODIFY_TEMPLATE_MAPPING';
export const DELETE_TEMPLATE_MAPPING = 'DELETE_TEMPLATE_MAPPING';

export const FETCH_TEMPLATE_EVENTS_PAGE = 'FETCH_TEMPLATE_EVENTS_PAGE';
export const FETCH_TEMPLATE_EVENT = 'FETCH_TEMPLATE_EVENT';
export const CREATE_TEMPLATE_EVENT = 'CREATE_TEMPLATE_EVENT';
export const MODIFY_TEMPLATE_EVENT = 'MODIFY_TEMPLATE_EVENT';
export const DELETE_TEMPLATE_EVENT = 'DELETE_TEMPLATE_EVENT';

export const FETCH_COMMENTS_PAGE = 'FETCH_COMMENTS_PAGE';
export const FETCH_COMMENT = 'FETCH_COMMENT';
export const CREATE_COMMENT = 'CREATE_COMMENT';
export const MODIFY_COMMENT = 'MODIFY_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';

/**
 * Social
 */
export const FETCH_WATCHLIST_PAGE = 'FETCH_WATCHLIST_PAGE';
export const CREATE_WATCHLIST_RECORD = 'CREATE_WATCHLIST_RECORD';
export const DELETE_WATCHLIST_RECORD = 'DELETE_WATCHLIST_RECORD';

export const FETCH_NOTIFICATIONS_PAGE = 'FETCH_NOTIFICATIONS_PAGE';
export const FETCH_NOTIFICATION = 'FETCH_NOTIFICATION';
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const MODIFY_NOTIFICATION = 'MODIFY_NOTIFICATION';
export const MARK_ALL_NOTIFICATIONS_AS_READ = 'MARK_ALL_NOTIFICATIONS_AS_READ';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_ALL_NOTIFICATIONS = 'DELETE_ALL_NOTIFICATIONS';
export const FETCH_NOTIFICATIONS_UNREAD_COUNT = 'FETCH_NOTIFICATIONS_UNREAD_COUNT';

export const FETCH_USER_NOTIFICATIONS_SETTINGS = 'FETCH_USER_NOTIFICATIONS_SETTINGS';
export const MODIFY_USER_NOTIFICATIONS_SETTINGS = 'MODIFY_USER_NOTIFICATIONS_SETTINGS';

/**
 * Dictionaries
 */
export const FETCH_DICTIONARYS_PAGE = 'FETCH_DICTIONARYS_PAGE';
export const FETCH_DICTIONARY = 'FETCH_DICTIONARY';
export const CREATE_DICTIONARY = 'CREATE_DICTIONARY';
export const DELETE_DICTIONARY = 'DELETE_DICTIONARY';
export const MODIFY_DICTIONARY = 'MODIFY_DICTIONARY';
export const SET_DICTIONARIES_FILTER = 'SET_DICTIONARIES_FILTER';
export const CLEAR_DICTIONARIES_FILTER = 'CLEAR_DICTIONARIES_FILTER';
export const EXPORT_CLASSIFICATION_DICTIONARIES = 'EXPORT_CLASSIFICATION_DICTIONARIES';

/**
 * Rules
 */
export const FETCH_RULES_PAGE = 'FETCH_RULES_PAGE';
export const FETCH_RULE = 'FETCH_RULE';
export const CREATE_RULE = 'CREATE_RULE';
export const DELETE_RULE = 'DELETE_RULE';
export const MODIFY_RULE = 'MODIFY_RULE';
export const SET_RULES_FILTERS = 'SET_RULES_FILTERS';
export const CLEAR_RULES_FILTERS = 'CLEAR_RULES_FILTERS';
export const EXPORT_CLASSIFICATION_RULES = 'EXPORT_CLASSIFICATION_RULES';

/**
 * Assignments
 */
export const FETCH_ASSIGNMENTS_PAGE = 'FETCH_ASSIGNMENTS_PAGE';
export const FETCH_ASSIGNMENT = 'FETCH_ASSIGNMENT';
export const CREATE_ASSIGNMENT = 'CREATE_ASSIGNMENT';
export const DELETE_ASSIGNMENT = 'DELETE_ASSIGNMENT';
export const MODIFY_ASSIGNMENT = 'MODIFY_ASSIGNMENT';
export const SET_ASSIGNMENTS_FILTERS = 'SET_ASSIGNMENTS_FILTERS';
export const CLEAR_ASSIGNMENTS_FILTERS = 'CLEAR_ASSIGNMENTS_FILTERS';
export const PATCH_ASSIGNMENT = 'FETCH_ASSIGNMENT';
export const EXPORT_CLASSIFICATION_ASSIGNMENTS = 'EXPORT_CLASSIFICATION_ASSIGNMENTS';

/**
 * Assignments Config
 */
export const FETCH_ASSIGNMENTS_CONFIG = 'FETCH_ASSIGNMENTS_CONFIG';
export const MODIFY_ASSIGNMENTS_CONFIG = 'MODIFY_ASSIGNMENTS_CONFIG';

export const FETCH_CLASSIFICATION_AGENT_SETTINGS_PAGE = 'FETCH_CLASSIFICATION_AGENT_SETTINGS_PAGE';
export const REQUEST_CLASSIFICATION_AGENT_SETTINGS = 'REQUEST_CLASSIFICATION_AGENT_SETTINGS';
export const CREATE_CLASSIFICATION_AGENT_SETTING = 'CREATE_CLASSIFICATION_AGENT_SETTING';
export const MODIFY_CLASSIFICATION_AGENT_SETTING = 'MODIFY_CLASSIFICATION_AGENT_SETTING';
export const DELETE_CLASSIFICATION_AGENT_SETTING = 'DELETE_CLASSIFICATION_AGENT_SETTING';

/**
 * Data Quality Suites
 */
export const FETCH_QUALITY_SUITE = 'FETCH_QUALITY_SUITE';
export const FETCH_QUALITY_SUITES_PAGE = 'FETCH_QUALITY_SUITES_PAGE';
export const SEARCH_QUALITY_SUITES = 'SEARCH_QUALITY_SUITES';
export const CREATE_QUALITY_SUITE = 'CREATE_QUALITY_SUITE';
export const MODIFY_QUALITY_SUITE = 'MODIFY_QUALITY_SUITE';
export const DELETE_QUALITY_SUITE = 'DELETE_QUALITY_SUITE';
export const UPDATE_CHECKS_OPTIONS_ENABLED = 'UPDATE_CHECKS_OPTIONS_ENABLED';
export const EXPORT_QUALITY_SUITES = 'EXPORT_QUALITY_SUITES';

/**
 * Data Quality Tags
 */
export const FETCH_QUALITY_TAG = ' FETCH_QUALITY_TAG';
export const FETCH_QUALITY_TAGS_PAGE = 'FETCH_QUALITY_TAGS_PAGE';
export const SEARCH_QUALITY_TAGS = 'SEARCH_QUALITY_TAGS';
export const CREATE_QUALITY_TAG = 'CREATE_QUALITY_TAG';
export const MODIFY_QUALITY_TAG = 'MODIFY_QUALITY_TAG';
export const DELETE_QUALITY_TAG = 'DELETE_QUALITY_TAG';
export const RESTORE_QUALITY_TAG = 'RESTORE_QUALITY_TAG';

/**
 * Data Quality Probes
 */

export const FETCH_PROBE_CONNECTIONS_PAGE = 'FETCH_PROBE_CONNECTIONS_PAGE';
export const FETCH_PROBE_CONNECTION = 'FETCH_PROBE_CONNECTION';
export const CREATE_PROBE_CONNECTION = 'CREATE_PROBE_CONNECTION';
export const MODIFY_PROBE_CONNECTION = 'MODIFY_PROBE_CONNECTION';
export const DELETE_PROBE_CONNECTION = 'DELETE_PROBE_CONNECTION';
export const SEARCH_PROBE_CONNECTIONS = 'SEARCH_PROBE_CONNECTIONS';
export const EXPORT_PROBE_CONNECTIONS = 'EXPORT_PROBE_CONNECTIONS';
export const FETCH_PROBE_PROJECTS_PAGE = 'FETCH_PROBE_PROJECTS_PAGE';
export const FETCH_PROBE_PROJECT = 'FETCH_PROBE_PROJECT';
export const CREATE_PROBE_PROJECT = 'CREATE_PROBE_PROJECT';
export const MODIFY_PROBE_PROJECT = 'MODIFY_PROBE_PROJECT';
export const DELETE_PROBE_PROJECT = 'DELETE_PROBE_PROJECT';
export const EXPORT_PROBE_PROJECTS = 'EXPORT_PROBE_PROJECTS';
export const FETCH_PROBE_DEFINITIONS_PAGE = 'FETCH_PROBE_DEFINITIONS_PAGE';
export const FETCH_PROBE_DEFINITION = 'FETCH_PROBE_DEFINITION';
export const FETCH_PROBE_DEFINITION_WITH_ROOT = 'FETCH_PROBE_DEFINITION_WITH_ROOT';
export const CREATE_PROBE_DEFINITION = 'CREATE_PROBE_DEFINITION';
export const MODIFY_PROBE_DEFINITION = 'MODIFY_PROBE_DEFINITION';
export const DELETE_PROBE_DEFINITION = 'DELETE_PROBE_DEFINITION';
export const EXPORT_PROBE_DEFINITIONS = 'EXPORT_PROBE_DEFINITIONS';

/**
 * Data Quality Checks
 */
export const FETCH_QUALITY_CHECK = 'FETCH_QUALITY_CHECK';
export const FETCH_QUALITY_CHECKS_PAGE = 'FETCH_QUALITY_CHECKS_PAGE';
export const FETCH_QUALITY_CHECK_GROUP_CODES = 'FETCH_QUALITY_CHECK_GROUP_CODES';
export const SEARCH_QUALITY_CHECKS = 'SEARCH_QUALITY_CHECKS';
export const CREATE_QUALITY_CHECK = 'CREATE_QUALITY_CHECK';
export const MODIFY_QUALITY_CHECK = 'MODIFY_QUALITY_CHECK';
export const DELETE_QUALITY_CHECK = 'DELETE_QUALITY_CHECK';
export const EXPORT_QUALITY_CHECK = 'EXPORT_QUALITY_CHECK';
export const FETCH_QUALITY_CHECKS_PANEL_PAGE = 'FETCH_QUALITY_CHECKS_PANEL_PAGE';

/**
 * Data Quality Results
 */
export const FETCH_QUALITY_RESULTS_PAGE = 'FETCH_QUALITY_RESULTS_PAGE';
export const CREATE_QUALITY_RESULT = 'CREATE_QUALITY_RESULT';
export const DELETE_QUALITY_RESULT = 'DELETE_QUALITY_RESULT';
export const MODIFY_QUALITY_RESULT = 'MODIFY_QUALITY_RESULT';
export const SET_QUALITY_RESULTS_FILTERS = 'SET_QUALITY_RESULTS_FILTERS';
export const CLEAR_QUALITY_RESULTS_FILTERS = 'CLEAR_QUALITY_RESULTS_FILTERS';
export const FETCH_QUALITY_RESULT = 'FETCH_QUALITY_RESULT';
export const EXPORT_QUALITY_RESULTS = 'EXPORT_QUALITY_RESULTS';

/**
 * Data Quality Dashboards
 */
export const FETCH_QUALITY_CHECKS_SEMAPHORES_PIE_CHART =
  'FETCH_QUALITY_CHECKS_SEMAPHORES_PIE_CHART';
export const FETCH_QUALITY_CHECKS_SEMAPHORES_DRILLDOWN =
  'FETCH_QUALITY_CHECKS_SEMAPHORES_DRILLDOWN';
export const FETCH_QUALITY_CHECKS_TIMELINE_BY_TEST_SUITE =
  'FETCH_QUALITY_CHECKS_TIMELINE_BY_TEST_SUITE';
export const SET_QUALITY_CHECKS_TIMELINE_BY_TEST_SUITE_FILTERS =
  'SET_QUALITY_CHECKS_TIMELINE_BY_TEST_SUITE_FILTERS';
export const FETCH_QUALITY_RESULTS_AREA_CHART = 'FETCH_QUALITY_RESULTS_AREA_CHART';
export const FETCH_QUALITY_CHECKS_TIMELINE_BY_CHECK = 'FETCH_QUALITY_CHECKS_TIMELINE_BY_CHECK';
export const FETCH_QUALITY_CHECKS_STATS = 'FETCH_QUALITY_CHECKS_STATS';
export const FETCH_QUALITY_CHECKS_TIMELINE_OVERALL = 'FETCH_QUALITY_CHECKS_TIMELINE_OVERALL';
export const SET_QUALITY_CHECKS_TIMELINE_BY_CHECK_FILTERS =
  'SET_QUALITY_CHECKS_TIMELINE_BY_CHECK_FILTERS';
export const SET_QUALITY_DASHBOARDS_FILTERS = 'SET_QUALITY_DASHBOARDS_FILTERS';
export const CLEAR_QUALITY_DASHBOARDS_FILTERS = 'CLEAR_QUALITY_DASHBOARDS_FILTERS';
export const FETCH_QUALITY_DASHBOARDS_OVERALL = 'FETCH_QUALITY_DASHBOARDS_OVERALL';
export const FETCH_HOME_QUALITY_CHECKS_SEMAPHORES_PIE_CHART =
  ' FETCH_HOME_QUALITY_CHECKS_SEMAPHORES_PIE_CHART';
export const FETCH_HOME_QUALITY_CHECKS_SEMAPHORES_DRILLDOWN =
  'FETCH_HOME_QUALITY_CHECKS_SEMAPHORES_DRILLDOWN';

/**
 * Data Quality Profiling Monitors
 */
export const SEARCH_PROFILING_MONITOR = 'SEARCH_PROFILING_MONITOR';
export const FETCH_PROFILING_MONITORS_PAGE = 'FETCH_PROFILING_MONITORS_PAGE';
export const FETCH_PROFILING_MONITOR = 'FETCH_PROFILING_MONITOR';
export const CREATE_PROFILING_MONITOR = 'CREATE_PROFILING_MONITOR';
export const DELETE_PROFILING_MONITOR = 'DELETE_PROFILING_MONITOR';
export const MODIFY_PROFILING_MONITOR = 'MODIFY_PROFILING_MONITOR';
export const SET_PROFILING_MONITORS_FILTERS = 'SET_PROFILING_MONITORS_FILTERS';
export const CLEAR_PROFILING_MONITORS_FILTERS = 'CLEAR_PROFILING_MONITORS_FILTERS';

/**
 * Data Quality Profiling Metric Definitions
 */
export const SEARCH_PROFILING_METRIC_DEFINITION = 'SEARCH_PROFILING_METRIC_DEFINITION';
export const FETCH_PROFILING_METRIC_DEFINITIONS_PAGE = 'FETCH_PROFILING_METRIC_DEFINITIONS_PAGE';
export const FETCH_PROFILING_METRIC_DEFINITION = 'FETCH_PROFILING_METRIC_DEFINITION';
export const CREATE_PROFILING_METRIC_DEFINITION = 'CREATE_PROFILING_METRIC_DEFINITION';
export const DELETE_PROFILING_METRIC_DEFINITION = 'DELETE_PROFILING_METRIC_DEFINITION';
export const MODIFY_PROFILING_METRIC_DEFINITION = 'MODIFY_PROFILING_METRIC_DEFINITION';
export const DELETE_PROFILING_METRIC_DEFINITIONS_BY_PROFILING_MONITOR =
  'DELETE_PROFILING_METRIC_DEFINITIONS_BY_PROFILING_MONITOR';
export const SET_PROFILING_METRIC_DEFINITIONS_FILTERS = 'SET_PROFILING_METRIC_DEFINITIONS_FILTERS';
export const CLEAR_PROFILING_METRIC_DEFINITIONS_FILTERS =
  'CLEAR_PROFILING_METRIC_DEFINITIONS_FILTERS';

/**
 * Data Quality Profiling Metric Records
 */
export const FETCH_PROFILING_METRIC_RECORDS = 'FETCH_PROFILING_METRIC_RECORDS';
export const CREATE_PROFILING_METRIC_RECORD = 'CREATE_PROFILING_METRIC_RECORD';
export const MODIFY_PROFILING_METRIC_RECORD = 'MODIFY_PROFILING_METRIC_RECORD';
export const DELETE_PROFILING_METRIC_RECORD = 'DELETE_PROFILING_METRIC_RECORD';
export const SET_METRIC_RECORDS_FILTERS = 'SET_PROFILING_METRIC_RECORDS_FILTERS';
export const CLEAR_METRIC_RECORDS_FILTERS = 'CLEAR_PROFILING_METRIC_RECORDS_FILTERS';

/**
 * Data Quality Profiling Metric Forecasts
 */
export const FETCH_PROFILING_METRIC_FORECASTS = 'FETCH_PROFILING_METRIC_FORECASTS';

/**
 * Data Quality Metric Anomalies
 */
export const SEARCH_PROFILING_METRIC_ANOMALY = 'SEARCH_PROFILING_METRIC_ANOMALY';
export const FETCH_PROFILING_METRIC_ANOMALYS_PAGE = 'FETCH_PROFILING_METRIC_ANOMALYS_PAGE';
export const FETCH_PROFILING_METRIC_ANOMALY = 'FETCH_PROFILING_METRIC_ANOMALY';
export const CREATE_PROFILING_METRIC_ANOMALY = 'CREATE_PROFILING_METRIC_ANOMALY';
export const DELETE_PROFILING_METRIC_ANOMALY = 'DELETE_PROFILING_METRIC_ANOMALY';
export const MODIFY_PROFILING_METRIC_ANOMALY = 'MODIFY_PROFILING_METRIC_ANOMALY';
export const SET_PROFILING_METRIC_ANOMALYS_FILTERS = 'SET_PROFILING_METRIC_ANOMALYS_FILTERS';
export const CLEAR_PROFILING_METRIC_ANOMALYS_FILTERS = 'CLEAR_PROFILING_METRIC_ANOMALYS_FILTERS';
export const FETCH_PROFILING_METRIC_ANOMALYS_BADGE_NUMBER =
  'FETCH_PROFILING_METRIC_ANOMALYS_BADGE_NUMBER';
export const FETCH_PROFILING_METRIC_ANOMALYS_OCCURRENCES =
  'FETCH_PROFILING_METRIC_ANOMALYS_OCCURRENCES';
/**
 * Data Quality Metric Incidents
 */

export const SEARCH_PROFILING_METRIC_INCIDENT = 'SEARCH_PROFILING_METRIC_INCIDENT';
export const FETCH_PROFILING_METRIC_INCIDENTS_PAGE = 'FETCH_PROFILING_METRIC_INCIDENTS_PAGE';
export const FETCH_PROFILING_METRIC_INCIDENT = 'FETCH_PROFILING_METRIC_INCIDENT';
export const CREATE_PROFILING_METRIC_INCIDENT = 'CREATE_PROFILING_METRIC_INCIDENT';
export const DELETE_PROFILING_METRIC_INCIDENT = 'DELETE_PROFILING_METRIC_INCIDENT';
export const MODIFY_PROFILING_METRIC_INCIDENT = 'MODIFY_PROFILING_METRIC_INCIDENT';
export const SET_PROFILING_METRIC_INCIDENTS_FILTERS = 'SET_PROFILING_METRIC_INCIDENTS_FILTERS';
export const CLEAR_PROFILING_METRIC_INCIDENTS_FILTERS = 'CLEAR_PROFILING_METRIC_INCIDENTS_FILTERS';
export const FETCH_PROFILING_METRIC_INCIDENTS_OCCURRENCES =
  'FETCH_PROFILING_METRIC_INCIDENTS_OCCURRENCES';

/**
 * Engagement Dashboards
 */

export const FETCH_ENGAGEMENT_SESSION_METRICS = 'FETCH_ENGAGEMENT_SESSION_COUNT';
export const FETCH_ENGAGEMENT_PAGE_VIEWS_METRICS = 'FETCH_ENGAGEMENT_PAGE_VIEWS_METRICS';
export const FETCH_ENGAGEMENT_SESSION_TIMELINE = 'FETCH_ENGAGEMENT_SESSION_TIMELINE';
export const FETCH_ENGAGEMENT_PAGE_VIEWS_TIMELINE = 'FETCH_ENGAGEMENT_PAGE_VIEWS_TIMELINE';
export const SET_ENGAGEMENT_TIMELINE_FILTERS = 'SET_ENGAGEMENT_TIMELINE_FILTERS';
export const CLEAR_ENGAGEMENT_TIMELINE_FILTERS = 'CLEAR_ENGAGEMENT_TIMELINE_FILTERS';
export const FETCH_ENGAGEMENT_RECENTLY_VIEWED_PAGE = 'FETCH_ENGAGEMENT_RECENTLY_VIEWED_PAGE';

/**
 * Data Quality Highlights
 */
export const FETCH_PHYSICAL_ENTITIES_WITH_QUALITY_HIGHLIGHTS =
  'FETCH_PHYSICAL_ENTITIES_WITH_QUALITY_HIGHLIGHTS';
export const FETCH_PHYSICAL_FIELDS_WITH_QUALITY_HIGHLIGHTS =
  'FETCH_PHYSICAL_FIELDS_WITH_QUALITY_HIGHLIGHTS';
export const FETCH_DATA_PRODUCT_WITH_QUALITY_HIGHLIGHTS =
  'FETCH_DATA_PRODUCT_WITH_QUALITY_HIGHLIGHTS';

/**
 * Stewardship
 */
export const FETCH_STEWARDSHIP_ROLE = 'FETCH_STEWARDSHIP_ROLE';
export const SEARCH_STEWARDSHIP_ROLES = 'SEARCH_STEWARDSHIP_ROLES';
export const FETCH_STEWARDSHIP_ROLES_PAGE = 'FETCH_STEWARDSHIP_ROLES_PAGE';
export const CREATE_STEWARDSHIP_ROLE = 'CREATE_STEWARDSHIP_ROLE';
export const MODIFY_STEWARDSHIP_ROLE = 'MODIFY_STEWARDSHIP_ROLE';
export const DELETE_STEWARDSHIP_ROLE = 'DELETE_STEWARDSHIP_ROLE';
export const CREATE_STEWARDSHIP_RESPONSIBILITY = 'CREATE_STEWARDSHIP_RESPONSIBILITY';
export const FETCH_STEWARDSHIP_RESPONSIBILITYS_PAGE = 'FETCH_STEWARDSHIP_RESPONSIBILITYS_PAGE';
export const FETCH_STEWARDSHIP_RESPONSIBILITYS_PAGE_BY_RESOURCE_TYPE =
  'FETCH_STEWARDSHIP_RESPONSIBILITYS_PAGE_BY_RESOURCE_TYPE';
export const DELETE_STEWARDSHIP_RESPONSIBILITY = 'DELETE_STEWARDSHIP_RESPONSIBILITY';
export const FETCH_STEWARDSHIP_RESPONSIBILITY = 'FETCH_STEWARDSHIP_RESPONSIBILITY';
export const MODIFY_STEWARDSHIP_RESPONSIBILITY = 'MODIFY_STEWARDSHIP_RESPONSIBILITY';
export const EXPORT_STEWARDSHIP_RESPONSIBILITY = 'EXPORT_STEWARDSHIP_RESPONSIBILITY';
export const EXPORT_STEWARDSHIP_ROLE = 'EXPORT_STEWARDSHIP_ROLE';
export const EXPORT_STEWARDSHIP_ASSIGNMENTS = 'EXPORT_STEWARDSHIP_ASSIGNMENTS';
export const FETCH_STEWARDSHIP_ASSIGNMENT = 'FETCH_STEWARDSHIP_ASSIGNMENT';
export const FETCH_STEWARDSHIP_ASSIGNMENTS_PAGE = 'FETCH_STEWARDSHIP_ASSIGNMENTS_PAGE';
export const CREATE_STEWARDSHIP_ASSIGNMENT = 'CREATE_STEWARDSHIP_ASSIGNMENT';
export const MODIFY_STEWARDSHIP_ASSIGNMENT = 'MODIFY_STEWARDSHIP_ASSIGNMENT';
export const DELETE_STEWARDSHIP_ASSIGNMENT = 'DELETE_STEWARDSHIP_ASSIGNMENT';
export const FETCH_ACL_STEWARDSHIP_RESPONSIBILITIES = 'FETCH_ACL_STEWARDSHIP_RESPONSIBILITIES';
export const FETCH_STEWARDSHIP_DASHBOARD_TABLE = 'FETCH_STEWARDSHIP_DASHBOARD_TABLE';
export const FETCH_STEWARDSHIP_DASHBOARD_ROLES = 'FETCH_STEWARDSHIP_DASHBOARD_ROLES';
export const FETCH_STEWARDSHIP_DASHBOARD_OVERALL = 'FETCH_STEWARDSHIP_DASHBOARD_OVERALL';
export const FETCH_STEWARDSHIP_DASHBOARD_USERS_TABLE = 'FETCH_STEWARDSHIP_DASHBOARD_USERS_TABLE';
export const FETCH_STEWARDSHIP_RESPONSIBILITIES_BY_RESOURCE =
  'FETCH_STEWARDSHIP_RESPONSIBILITIES_BY_RESOURCE';
/**
 * Settings Configurations
 */
export const FETCH_SETTINGS_CONFIGURATION = 'FETCH_SETTINGS_CONFIGURATION';
export const FETCH_SETTINGS_CONFIGURATIONS_PAGE = 'FETCH_SETTINGS_CONFIGURATIONS_PAGE';
export const CREATE_SETTINGS_CONFIGURATION = 'CREATE_SETTINGS_CONFIGURATION';
export const MODIFY_SETTINGS_CONFIGURATION = 'MODIFY_SETTINGS_CONFIGURATION';
export const DELETE_SETTINGS_CONFIGURATION = 'DELETE_SETTINGS_CONFIGURATION';

/**
 * Agents
 */
export const FETCH_AGENTS_PAGE = 'FETCH_AGENTS_PAGE';
export const FETCH_AGENT = 'FETCH_AGENT';
export const CREATE_AGENT = 'CREATE_AGENT';
export const MODIFY_AGENT = 'MODIFY_AGENT';
export const DELETE_AGENT = 'DELETE_AGENT';
export const PAIR_AGENT = 'PAIR_AGENT';
export const TEST_PROBE_ON_AGENT = 'TEST_PROBE_ON_AGENT';
export const TEST_PROBE_ASYNC_ON_AGENT = 'TEST_PROBE_ASYNC_ON_AGENT';
export const SEARCH_AGENTS = 'SEARCH_AGENTS';
export const RUN_PROJECT_ON_AGENT = 'RUN_PROJECT_ON_AGENT';
export const RUN_PROJECT_ASYNC_ON_AGENT = 'RUN_PROJECT_ASYNC_ON_AGENT';
export const FETCH_AGENT_JOB_HISTORYS_PAGE = 'FETCH_AGENT_JOB_HISTORYS_PAGE';
export const FETCH_AGENT_JOB_HISTORY = 'FETCH_AGENT_JOB_HISTORY';
export const FETCH_AGENT_JOB_HISTORY_LOG = 'FETCH_AGENT_JOB_HISTORY_LOG';

/**
 * On agent actions
 */
export const SEARCH_AGENT_CONNECTIONS = 'SEARCH_AGENT_CONNECTIONS';
export const FETCH_AGENT_CONNECTIONS_PAGE = 'FETCH_AGENT_CONNECTIONS_PAGE';
export const FETCH_AGENT_CONNECTION = 'FETCH_AGENT_CONNECTION';
export const CREATE_AGENT_CONNECTION = 'CREATE_AGENT_CONNECTION';
export const MODIFY_AGENT_CONNECTION = 'MODIFY_AGENT_CONNECTION';
export const DELETE_AGENT_CONNECTION = 'DELETE_AGENT_CONNECTION';
export const TEST_AGENT_CONNECTION = 'TEST_AGENT_CONNECTION';
export const CREATE_AGENT_SCHEDULE = 'CREATE_AGENT_SCHEDULE';
export const FETCH_AGENT_SCHEDULES_PAGE = 'FETCH_AGENT_SCHEDULES_PAGE';
export const FETCH_AGENT_SCHEDULE = 'FETCH_AGENT_SCHEDULE';
export const MODIFY_AGENT_SCHEDULE = 'MODIFY_AGENT_SCHEDULE';
export const DELETE_AGENT_SCHEDULE = 'DELETE_AGENT_SCHEDULE';

/**
 * Users Engagement Actions
 */
export const ADD_ENGAGEMENT_HIT = 'ADD_ENGAGEMENT_HIT';
// export const SEND_ENGAGEMENT_HITS = 'SEND_ENGAGEMENT_HITS';
export const DELETE_ENGAGEMENT_HITS = 'DELETE_ENGAGEMENT_HITS';

/**
 * Quality Assessment
 */
export const FETCH_QUALITY_ASSESSMENT_CONFIGURATION = 'FETCH_QUALITY_ASSESSMENT_CONFIGURATION';
export const FETCH_RISK_DEFINITIONS_PAGE = 'FETCH_RISK_DEFINITIONS_PAGE';
export const FETCH_RISK_DEFINITION = 'FETCH_RISK_DEFINITION';
export const CREATE_RISK_DEFINITION = 'CREATE_RISK_DEFINITION';
export const MODIFY_RISK_DEFINITION = 'MODIFY_RISK_DEFINITION';
export const DELETE_RISK_DEFINITION = 'DELETE_RISK_DEFINITION';
export const EXPORT_RISK_DEFINITIONS = 'EXPORT_RISK_DEFINITIONS';
export const FETCH_RISK_CATEGORIES = 'FETCH_RISK_CATEGORIES';
export const FETCH_PE_RISK_EVALUATION = 'FETCH_PE_RISK_EVALUATION';
export const MODIFY_PE_RISK_EVALUATION = 'MODIFY_PE_RISK_EVALUATION';
export const DELETE_PE_RISK_EVALUATION = 'DELETE_PE_RISK_EVALUATION';
export const EXPORT_PE_RISK_EVALUATIONS = 'EXPORT_PE_RISK_EVALUATION';
export const FETCH_CHECK_EVALUATION = 'FETCH_CHECK_EVALUATION';
export const FETCH_CHECK_EVALUATIONS_BY_PE_AND_RISK = 'FETCH_CHECK_EVALUATIONS_BY_PE_AND_RISK';
export const MODIFY_CHECK_EVALUATION = 'MODIFY_CHECK_EVALUATION';
export const DELETE_CHECK_EVALUATION = 'DELETE_CHECK_EVALUATION';
export const EXPORT_CHECK_EVALUATIONS = 'EXPORT_CHECK_EVALUATION';
export const FETCH_QUALITY_ASSESSMENT_RESIDUAL_RISK_TABLE_DASHBOARD =
  'FETCH_QUALITY_ASSESSMENT_RESIDUAL_RISK_TABLE_DASHBOARD';
export const FETCH_QUALITY_ASSESSMENT_RESIDUAL_RISK_COUNTER_DASHBOARD =
  'FETCH_QUALITY_ASSESSMENT_RESIDUAL_RISK_COUNTER_DASHBOARD';
export const EXPORT_RESIDUAL_RISK_TABLE = 'EXPORT_RESIDUAL_RISK_TABLE';

/**
 * Workspace
 */
export const FETCH_WORKSPACES_PAGE = 'FETCH_WORKSPACES_PAGE';
export const CREATE_WORKSPACE = 'CREATE_WORKSPACE';
export const FETCH_WORKSPACE = 'FETCH_WORKSPACE';
export const DELETE_WORKSPACE = 'DELETE_WORKSPACE';
export const MODIFY_WORKSPACE = 'MODIFY_WORKSPACE';

/**
 * Favorite
 */
export const FETCH_FAVORITES_PAGE = 'FETCH_FAVORITES_PAGE';
export const CREATE_FAVORITE = 'CREATE_FAVORITE';
export const FETCH_FAVORITE = 'FETCH_FAVORITE';
export const DELETE_FAVORITE = 'DELETE_FAVORITE';
export const MODIFY_FAVORITE = 'MODIFY_FAVORITE';

/**
 * Markdown Documentation
 */
export const FETCH_DOCUMENTS_CONTENTS_PAGE = 'FETCH_DOCUMENTS_CONTENTS_PAGE';
export const FETCH_DOCUMENT_CONTENT = 'FETCH_DOCUMENT_CONTENT';
export const CREATE_DOCUMENT_CONTENT = 'CREATE_DOCUMENT_CONTENT';
export const MODIFY_DOCUMENT_CONTENT = 'MODIFY_DOCUMENT_CONTENT';
export const DELETE_DOCUMENT_CONTENT = 'DELETE_DOCUMENT_CONTENT';
