import { FETCH_GOVERNANCE_POLICY_TIMELINE_DASHBOARD } from 'constants/ActionTypes';

const results = (state = {}, action) => {
  switch (action.type) {
    case FETCH_GOVERNANCE_POLICY_TIMELINE_DASHBOARD:
      return action.payload;
    default:
      return state;
  }
};

export default results;
